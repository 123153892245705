import Storage from "@/modules/storage";

const validatePassword = (rawPassword: string) => {
  const lowercase = "abcdefghijklmnopqrstuvwxyz";
  const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const specialLetters = "ñÑ~!@#$%^&*+-/.,{}[]();: ";
  const digits = "0123456789";

  let upper = 0;
  let lower = 0;
  let digit = 0;
  let special = 0;

  if (rawPassword.length > 50) {
    return [false, "over"];
  } else if (rawPassword.length < 8) {
    return [false, "under"];
  }

  for (let i = 0; i < rawPassword.length; i++) {
    if (lowercase.includes(rawPassword.charAt(i))) {
      lower++;
    } else if (uppercase.includes(rawPassword.charAt(i))) {
      upper++;
    } else if (specialLetters.includes(rawPassword.charAt(i))) {
      special++;
    } else if (digits.includes(rawPassword.charAt(i))) {
      digit++;
    } else {
      return [false, "not valid", rawPassword.charAt(i)];
    }
  }

  if (upper === 0) {
    return [false, "upper"];
  } else if (lower === 0) {
    return [false, "lower"];
  } else if (digit === 0) {
    return [false, "digit"];
  } else if (special === 0) {
    return [false, "special", specialLetters];
  }

  return [true, rawPassword];
};

const prettyDate = (timestamp: number) => {
  return new Promise((resolve, reject) => {
    if (!timestamp) {
      resolve(0);
    }
    const store = new Storage();
    store.getData("country", undefined).then((countryArray: any) => {
      countryArray.forEach((country: any) => {
        if (country._id == localStorage.getItem("user.country")) {
          const format =
            country.languageCode.toLowerCase() +
            "-" +
            country.ISOCode.toUpperCase();
          const newDate = new Date(timestamp);
          const dateFormater = new Intl.DateTimeFormat(format).format(newDate);
          resolve(dateFormater);
        }
      });
    });
  });
};

const prettyTime = (timestamp: number) => {
  return new Promise((resolve, reject) => {
    if (!timestamp) {
      resolve(0);
    }
    resolve(new Date(timestamp).toLocaleTimeString());
  });
};

const prettyCurrency = (amount: number) => {
  return new Promise((resolve, reject) => {
    const store = new Storage();
    store.getData("country", undefined).then((countryArray: any) => {
      countryArray.forEach((country: any) => {
        if (country._id == localStorage.getItem("user.country")) {
          const options = {
            style: 'currency',
            currency: country.currency.toUpperCase()
          };
          const format =
            country.languageCode.toLowerCase() +
            "-" +
            country.ISOCode.toUpperCase();
          const currencyFormater = new Intl.NumberFormat(format, options);
          resolve(currencyFormater.format(amount));
        }
      });
    });
  });
};

const prettyID = (ID: number) => {
  return new Promise((resolve, reject) => {
    const i = ID.toString();
    const j = i.length > 3 ? i.length % 3 : 0;
    resolve(
      (j ? i.substr(0, j) + "." : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + ".")
    );
  });
};

const titleCase = (text: string) => {
  return text.replace(/\w\S*/g, function(txt) {
    let prev: any = txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    prev = prev.split("_");
    return prev.join(" ");
  });
};

const dateToTimestamp = (date: string) => {
  return new Date(date).getTime();
}

const TimestampToDate = (timestamp: number) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();

  let month:any = date.getMonth()+1;
  let day:any = date.getDate();

  if (month < 10) {
    month = '0' + month.toString();
  }
  if (day < 10) {
    day = '0' + day.toString();
  }

  return year + '-' + month + '-' + day;
}

export { validatePassword, prettyDate, prettyID, titleCase, prettyCurrency, dateToTimestamp, TimestampToDate, prettyTime };
