<template>
    <div>
        <v-img
            v-if="!$vuetify.theme.dark"
            class="my-5"
            lazy-src="/img/logo_color.svg"
            src="/img/logo_color.svg"
            max-height="80"
            max-width="250"
            contain
        ></v-img>
        <v-img
            v-else
            class="my-5"
            lazy-src="/img/logo_white.svg"
            src="/img/logo_white.svg"
            max-height="80"
            max-width="250"
            contain
        ></v-img>
        <v-card
            class="d-flex justify-center mb-6"
            :color="'#00000000'"
            flat
            tile
        >
            <v-btn
            to="/subscription"
            class="center"
                color="primary"
                elevation="5"
            >Cambiar Plan</v-btn>
        </v-card>
        <v-list
            dense
            nav
        >
            <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.link"
            link
            >
            <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
export default {
    name: 'NavigationMain',
    data () {
      return {
        items: [
          { title: 'Dashboard', icon: 'mdi-view-dashboard', link: '/' },
          { title: 'Oficinas', icon: 'mdi-briefcase', link: '/office' },
          { title: 'Usuarios', icon: 'mdi-shield', link: '/user' },
          { title: 'Aportantes', icon: 'mdi-account-group', link: '/contributor' },
          { title: 'Sucursales', icon: 'mdi-office-building', link: '/branch' },
          { title: 'Afiliados', icon: 'mdi-account', link: '/affiliate' },
          { title: 'Recaudos', icon: 'mdi-file-chart', link: '/collection' },
        ],
        right: null,
      }
    },
}
</script>