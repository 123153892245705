<template>
    <div class="d-flex align-center mr-2" style="cursor: pointer;" @click="showNotifications">
        <v-badge
            overlap
            :content="notifications"
            :value="notifications"
        >
            <v-icon>
                mdi-bell
            </v-icon>
        </v-badge>
        <div class="text-center">
            <v-bottom-sheet v-model="sheet">
            <v-list>
                <v-subheader>Notificaciones</v-subheader>
                <v-list-item
                v-for="tile in tiles"
                :key="tile.title"
                @click="sheet = false"
                >
                <v-list-item-avatar>
                    <v-avatar
                    size="32px"
                    tile
                    >
                    <img
                        :src="`https://cdn.vuetifyjs.com/images/bottom-sheets/${tile.img}`"
                        :alt="tile.title"
                    >
                    </v-avatar>
                </v-list-item-avatar>
                <v-list-item-title>{{ tile.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-bottom-sheet>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderNotifications',
    data() {
        return {
            notifications: 3,
            sheet: false,
            tiles: [
                { img: 'keep.png', title: 'Keep' },
                { img: 'inbox.png', title: 'Inbox' },
                { img: 'hangouts.png', title: 'Hangouts' },
                { img: 'messenger.png', title: 'Messenger' },
                { img: 'google.png', title: 'Google+' },
                { img: 'keep.png', title: 'Keep' },
                { img: 'inbox.png', title: 'Inbox' },
                { img: 'hangouts.png', title: 'Hangouts' },
                { img: 'messenger.png', title: 'Messenger' },
                { img: 'google.png', title: 'Google+' },
                { img: 'keep.png', title: 'Keep' },
                { img: 'inbox.png', title: 'Inbox' },
                { img: 'hangouts.png', title: 'Hangouts' },
                { img: 'messenger.png', title: 'Messenger' },
                { img: 'google.png', title: 'Google+' },
                { img: 'keep.png', title: 'Keep' },
                { img: 'inbox.png', title: 'Inbox' },
                { img: 'hangouts.png', title: 'Hangouts' },
                { img: 'messenger.png', title: 'Messenger' },
                { img: 'google.png', title: 'Google+' },
            ],
        }
    },
    methods: {
        showNotifications() {
            this.notifications = 0;
            this.sheet = true;
        }
    },
    mounted() {
        if (this.notifications > 0) {
            document.title = `(${this.notifications}) Assoft | MiSalud`;
        } else {
            document.title = 'Assoft | MiSalud';
        }
    },
    watch: {
        notifications: function(value) {
            if (value > 0) {
                document.title = `(${this.notifications}) Assoft | MiSalud`;
            } else {
                document.title = 'Assoft | MiSalud';
            }
        }
    }
}
</script>