import Global from "./globals";
import Swal from "sweetalert2";

class Notificator extends Global {
  toast(msg: string, duration: number) {
    Swal.fire({
      icon: "warning",
      title: msg,
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      timer: duration,
      timerProgressBar: true,
      didOpen: (toast: any) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      }
    });
  }
  fullScreen(title: string, msg: string) {
    Swal.fire({
      icon: "error",
      title: title,
      text: msg,
      footer: "<a href>Informar un Problema</a>"
    });
  }
}

export default Notificator;
