




































import Vue from 'vue';

import Avatar from '@/components/Header/Avatar.vue'
import Notifications from '@/components/Header/Notifications.vue'
import Navigation from '@/components/Navigation/Main.vue'
import Calculator from '@/components/Layout/Calculator.vue'

export default Vue.extend({
  name: 'App',
  components: {
    Avatar,
    Notifications,
    Navigation,
    Calculator
  },
  data: () => ({
    drawer: null
  }),
  computed: {
    hide() {
      return (
        this.$route.path === "/login" ||
        this.$route.path === "/forgotPassword" ||
        this.$route.path === "/signup" ||
        this.$route.path === "/404" ||
        this.$route.path === "/loading" ||
        this.$route.path.includes("create") ||
        this.$route.path.includes("edit") ||
        this.$route.path.includes("download")
      );
    },
    hideCalculator() {
      return (
        this.$route.path === "/login" ||
        this.$route.path === "/forgotPassword" ||
        this.$route.path === "/signup" ||
        this.$route.path === "/404" ||
        this.$route.path === "/loading"
      );
    },
  }
});
