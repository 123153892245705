export default [
    {
      path: "/contributor",
      name: "Contributor",
      component: () => import("../views/OfficeSelector.vue")
    },
    {
      path: "/contributor/:_id",
      name: "Contributor Main",
      component: () => import("../views/Contributor/Main.vue")
    },
    {
      path: "/contributor/:_id/create",
      name: "Contributor Create",
      component: () => import("../views/Contributor/Create.vue")
    },
    {
      path: "/contributor/:_id/edit/:object_id",
      name: "Contributor Edit",
      component: () => import("../views/Contributor/Edit.vue")
    }
  ];
