import Global from "./globals";
import Notificator from "./notificator";
import axios from "axios";
import DB from "@/modules/db";

class Session extends Global {
  loginURL: string;
  sigOutURL: string;

  constructor() {
    super();
    this.loginURL = this.baseURL + "/auth/user/login";
    this.sigOutURL = this.baseURL + "/auth/user/signout";
  }
  check() {
    const checkInterval = setInterval(() => {
      const now = new Date().getTime();
      const TokenEXP = JSON.parse(localStorage.getItem("TokenEXP") || "{}");
      const secondsRemaining = Math.round((TokenEXP - now) / 1000);
      const minNotify30 = sessionStorage.getItem("minNotify30");
      const minNotify5 = sessionStorage.getItem("minNotify5");
      const minNotify1 = sessionStorage.getItem("minNotify1");

      if (secondsRemaining <= 0) {
        this.sigOut();
        clearInterval(checkInterval);
      } else if (secondsRemaining <= 60 && minNotify1 === null) {
        sessionStorage.setItem("minNotify1", "true");
        const expNotify = new Notificator().toast(
          "La sesión cerrará en 1 minuto",
          60000
        );
      } else if (secondsRemaining <= 300 && minNotify5 === null) {
        sessionStorage.setItem("minNotify5", "true");
        const expNotify = new Notificator().toast(
          "La sesión cerrará en 5 minutos",
          7000
        );
      } else if (secondsRemaining <= 1800 && minNotify30 === null) {
        sessionStorage.setItem("minNotify30", "true");
        const expNotify = new Notificator().toast(
          "La sesión cerrará en 30 minutos",
          7000
        );
      }
    }, 1000);
  }
  sigOut() {
    const sigOutURL = this.sigOutURL;
    let darkTheme = false;
    if (localStorage.getItem('darkTheme') === 'true') {
      darkTheme = true;
    }
    localStorage.clear();
    sessionStorage.clear();
    new DB().drop();
    if (darkTheme) {
      localStorage.setItem('darkTheme', 'true');
    } else {
      localStorage.setItem('darkTheme', 'false');
    }
    axios
      .post(
        sigOutURL,
        {},
        {
          withCredentials: true
        }
      )
      .then(function() {
        location.reload();
      })
      .catch(function() {
        location.reload();
      });
  }
  signInEmail(email: string, password: string) {
    const loginURL = this.loginURL;
    const user = new Promise((resolve, reject) => {
      axios
        .post(
          loginURL,
          {
            SOFTWARE: this.endpointName
          },
          {
            auth: {
              username: email,
              password: password
            },
            withCredentials: true
          }
        )
        .then(function(response: any) {
          Object.entries(response.data.body.userData).forEach(
            ([key, value]) => {
              localStorage.setItem("user." + key, value + "");
            }
          );
          localStorage.setItem("TokenEXP", response.data.body.JWT.TokenEXP);
          localStorage.setItem("TokenIAT", response.data.body.JWT.TokenIAT);
          resolve(response.data);
        })
        .catch(function(error: any) {
          console.log(error);
          reject(error);
        });
    });
    return user;
  }
}

export default Session;
