<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Concepto
          </th>
          <th class="text-left">
            Valor
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in desserts"
          :key="item.name"
        >
          <td>{{ item.name }}</td>
          <td>{{ item.value }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
  export default {
    data () {
      return {
        desserts: [
          {
            name: 'Salario Mínimo',
            value: '$ 1.000.000',
          },
          {
            name: 'Auxilio de Transporte',
            value: '$ 117.172',
          },
          {
            name: '(AFP) Administradora de Fondo de Pensión',
            value: '$ 160.000',
          },
          {
            name: '(EPS) Entidades Prestadoras de Salud',
            value: '$ 40.000',
          },
          {
            name: '(CCF) Caja de Compensación Familiar',
            value: '$ 40.000',
          },
          {
            name: '(I) Administradora de Riesgos Laborales | Riesgo 1',
            value: '$ 5.220',
          },
          {
            name: '(II) Administradora de Riesgos Laborales | Riesgo 2',
            value: '$ 10.440',
          },
          {
            name: '(III) Administradora de Riesgos Laborales | Riesgo 3',
            value: '$ 24.360',
          },
          {
            name: '(IV) Administradora de Riesgos Laborales | Riesgo 4',
            value: '$ 43.500',
          },
          {
            name: '(V) Administradora de Riesgos Laborales | Riesgo 5',
            value: '$ 69.600',
          },
        ],
      }
    },
  }
</script>