import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Session from '@/modules/session'
import Home from '../views/Dashboard/Home.vue'
import Auth from "./auth";
import Office from "./office";
import User from "./user";
import Contributor from "./contributor";
import Branch from "./branch";
import Affiliate from "./affiliate";
import Collection from "./collection";

Vue.use(VueRouter)

let routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import("../views/Settings.vue")
  },
  {
    path: "/loading",
    name: "Loader",
    component: () => import("../views/Loader.vue")
  },
  {
    path: "*",
    name: "Redirect Not Found",
    redirect: "/404"
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/NotFound.vue")
  }
]
routes = routes.concat(Auth);
routes = routes.concat(Office);
routes = routes.concat(User);
routes = routes.concat(Contributor);
routes = routes.concat(Branch);
routes = routes.concat(Affiliate);
routes = routes.concat(Collection);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/forgotPassword",
    "/signup",
    "/signout",
    "/sendEmail",
    "/resetPassword",
    "/404"
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("TokenEXP");

  if (authRequired && !loggedIn) {
    return next("/login");
  } else if (authRequired) {
    const checkSession = new Session().check();
    if (Notification.permission !== "denied") {
      Notification.requestPermission();
    }
  }

  next();
});

export default router
