<template>
  <v-card id="create">
    <v-speed-dial
      v-model="fab"
      :bottom="bottom"
      :right="right"
      :left="left"
      :direction="direction"
      :open-on-hover="hover"
      :transition="transition"
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="primary"
          dark
          fab
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-calculator
          </v-icon>
        </v-btn>
      </template>
      
        <v-container style="width: 300px; margin-left: -230px;" class="text-center clarity" @click.prevent="persistent">
            <v-card tile color="info" v-bind:class="{ hidePreValue: cPreProcessValue == '0' }"><p class="mb-2 pa-2 text-end">{{ cPreProcessValue }} {{ cOperationMode }}</p></v-card>
            <div class="elevation-10">
                <v-row>
                    <v-col class="py-0"><v-card class="pa-4 text-h5 text-end" tile>{{ cProcessValue }}</v-card></v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="9"><v-btn block tile color="primary" class="ma-0" @click="reset">C</v-btn></v-col>
                    <v-col><v-btn block tile class="ma-0" @click="operation('/')">/</v-btn></v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col><v-btn block tile class="ma-0" @click="addNumber('7')">7</v-btn></v-col>
                    <v-col><v-btn block tile class="ma-0" @click="addNumber('8')">8</v-btn></v-col>
                    <v-col><v-btn block tile class="ma-0" @click="addNumber('9')">9</v-btn></v-col>
                    <v-col><v-btn block tile class="ma-0" @click="operation('X')">X</v-btn></v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col><v-btn block tile class="ma-0" @click="addNumber('4')">4</v-btn></v-col>
                    <v-col><v-btn block tile class="ma-0" @click="addNumber('5')">5</v-btn></v-col>
                    <v-col><v-btn block tile class="ma-0" @click="addNumber('6')">6</v-btn></v-col>
                    <v-col><v-btn block tile class="ma-0" @click="operation('-')">-</v-btn></v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col><v-btn block tile class="ma-0" @click="addNumber('1')">1</v-btn></v-col>
                    <v-col><v-btn block tile class="ma-0" @click="addNumber('2')">2</v-btn></v-col>
                    <v-col><v-btn block tile class="ma-0" @click="addNumber('3')">3</v-btn></v-col>
                    <v-col><v-btn block tile class="ma-0" @click="operation('+')">+</v-btn></v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6"><v-btn block tile class="ma-0" @click="addNumber('0')">0</v-btn></v-col>
                    <v-col><v-btn block tile class="ma-0" @click="cMode = 'Float'">,</v-btn></v-col>
                    <v-col><v-btn block tile class="ma-0" @click="result">=</v-btn></v-col>
                </v-row>
            </div>
        </v-container>



    </v-speed-dial>
  </v-card>
</template>

<script>
import { prettyID } from "@/modules/tools";

  export default {
    data: () => ({
      direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',
      cValue: '',
      cDecimal: '',
      cProcessValue: '0',
      cMode: 'Int',
      cPreValue: '',
      cPreProcessValue: '0',
      cOperationMode: ''
    }),
    mounted() {
        document.addEventListener('keyup', (e) => {
            if (this.fab) {
                if (e.key == 'Enter') {
                   this.fab = true; 
                   this.result();
                } else if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(e.key)) {
                    this.addNumber(e.key);
                } else if (['+', '-', '/'].includes(e.key)) {
                    this.operation(e.key);
                } else if (e.key == '*') {
                    this.operation('X');
                } else if (e.key == 'Backspace' || e.key == 'Escape') {
                    this.reset();
                } else if (e.key == '.') {
                    this.cMode = 'Float'
                }
            }
        });
    },
    watch: {
        cMode: function (val) {
            if (val == 'Float' && this.cValue == '') {
                this.cValue = '0';
            }
        }
    },
    methods: {
        persistent: function () {
            this.transition = 'none';
            this.fab = false;
            setTimeout(() => {this.transition = 'slide-y-reverse-transition';}, 100)
        },
        addNumber: async function (value) {
            if (this.cProcessValue == 'NaN') {
                this.reset();
            }
            if (this.cMode === 'Int') {
                if (this.cValue.length > 11) {
                    return 0;
                }
                this.cValue += value;
            } else if (this.cMode === 'Float') {
                if (this.cDecimal.length > 3) {
                    return 0;
                }
                this.cDecimal += value;
            }

            let preValue = await prettyID(this.cValue);

            if (this.cDecimal != '') {
                preValue += ',' + this.cDecimal;
            }
            this.cProcessValue = preValue;
        },
        operation: async function (value) {
            if (this.cProcessValue == 'NaN') {
                this.reset();
            }
            if (this.cValue == '') {
                return 0;
            } else if (this.cOperationMode != '') {
                this.result();
                this.cProcessValue = await prettyID(this.cValue);
                if (parseInt(this.cDecimal) > 0) {
                    this.cProcessValue += ',';
                    this.cProcessValue += this.cDecimal;
                }
            }
            this.cPreValue = this.cValue+'.'+this.cDecimal;
            this.cPreProcessValue = this.cProcessValue;

            this.cValue = '';
            this.cProcessValue = '0';
            this.cDecimal = '';
            this.cMode = 'Int';

            this.cOperationMode = value;
        },
        reset: function () {
            this.cValue = '';
            this.cDecimal = '';
            this.cProcessValue = '0';
            this.cMode = 'Int';

            this.cPreValue = '';
            this.cPreProcessValue = '0';
        },
        result: async function () {
            let result = 0;
            this.cValue = parseFloat(this.cValue+'.'+this.cDecimal);
            this.cPreValue = parseFloat(this.cPreValue);

            if (this.cOperationMode == '') {
                return 0;
            } else if (this.cOperationMode == '+') {
                result = this.cValue + this.cPreValue;
            } else if (this.cOperationMode == '-') {
                result = this.cPreValue - this.cValue;
            } else if (this.cOperationMode == 'X') {
                result = this.cPreValue * this.cValue;
            } else if (this.cOperationMode == '/') {
                result = this.cPreValue / this.cValue;
            }

            this.reset();
            this.cValue = Math.trunc(result);
            this.cDecimal = result - this.cValue;
            this.cProcessValue = await prettyID(this.cValue);

            if (this.cDecimal > 0) {
                this.cDecimal = this.cDecimal.toString();
                this.cDecimal = this.cDecimal.substring(2);
                if (this.cDecimal.length > 2) {
                    let lastChar = this.cDecimal.charAt(1);
                    if (parseInt(this.cDecimal.charAt(2)) > 8) {
                        lastChar = (parseInt(lastChar) + 1).toString();
                    }
                    this.cDecimal = this.cDecimal.charAt(0) + lastChar;
                }
                if (this.cDecimal.charAt(1) == '0') {
                    this.cDecimal = this.cDecimal.charAt(0);
                }
                this.cProcessValue += ',';
                this.cProcessValue += this.cDecimal.toString();
            }
            this.cValue = this.cValue.toString();
            this.cOperationMode = '';

            if (this.cProcessValue == 'NaN') {
                this.reset();
            }
        }
    },
  }
</script>

<style>
  /* This is for documentation purposes and will not be needed in your application */
  #create .v-speed-dial {
    position: fixed;
  }

  #create .v-btn--floating {
    position: relative;
  }

  .clarity {
      opacity: 0.4;
      transition: 0.3s ease-in-out;
  }

  .clarity:hover {
      opacity: 1;
      transition: 0s;
  }

  .hidePreValue {
      transform: translateY(50px);
  }

  @media only screen and (max-width: 800px) {
    .clarity {
        opacity: 1;
    }
  }
</style>