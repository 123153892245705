import Global from "./globals";
import axios from "axios";

interface IndexedDB {
  open: any;
  deleteDatabase: any;
}

class Storage extends Global {
  indexedDB: IndexedDB;
  db: any;
  dbName: string;
  dbVersion: number;
  uploadLoaded: number;
  uploadTotal: number;
  uploadPercent: number;

  constructor() {
    super();
    this.indexedDB = window.indexedDB;
    this.dbVersion = 1;
    this.dbName = "epicDB";
    this.db = Object;
    this.uploadLoaded = 0;
    this.uploadTotal = 0;
    this.uploadPercent = 0;
  }
  IDGenerator() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  createDB(initStorageNames: string[], initStorageSchemas: string[]) {
    const commonStorage = ["trash", "schema"];
    const storageNames = initStorageNames.concat(commonStorage);
    if (this.indexedDB) {
      this.getSchemas(initStorageNames, initStorageSchemas);
      const request = this.indexedDB.open(this.dbName, this.dbVersion);
      request.onsuccess = () => {
        this.db = request.result;
      };
      request.onupgradeneeded = () => {
        this.db = request.result;
        storageNames.forEach(storageName => {
          this.db.createObjectStore(storageName, {
            keyPath: "_id"
          });
        });
      };
      request.onerror = (e: string) => {
        console.log("Error en Base de datos: ", e);
      };
    }
  }
  deleteDB() {
    const status = new Promise((resolve, reject) => {
      const req = this.indexedDB.deleteDatabase(this.dbName);
      req.onsuccess = function() {
        resolve("Deleted database successfully");
      };
      req.onerror = function() {
        reject("Couldn't delete database");
      };
    });
    return status;
  }
  getSchemas(storeNames: string[], storeSchemas: string[]) {
    const dataBody = new Promise((resolve, reject) => {
      storeNames.forEach((store, index) => {
        const schemaURL = storeSchemas[index];
        const URL = this.baseURL + "/" + schemaURL + "/" + store + "/schema";
        axios
          .get(URL, {
            withCredentials: true
          })
          .then(response => {
            const body = [
              {
                store: store,
                data: response.data.body,
                _id: this.IDGenerator(),
                lastUpdate: new Date().getTime()
              }
            ];
            this.addData("schema", body).catch(error => {
              reject(error);
            });
          })
          .catch(function(error) {
            console.log(error);
            reject(error);
          });
      });
      resolve(true);
    });
    return dataBody;
  }
  downloadData(schema: string, storeName: string, params: any) {
    const dataBody = new Promise((resolve, reject) => {
      let formatParams = "";
      let aux = 0;
      if (params !== undefined) {
        formatParams += "?";
        Object.entries(params).forEach(([key, value]) => {
          if (aux >= 1) {
            formatParams += "&";
          }
          formatParams += key;
          formatParams += "=";
          formatParams += value;
          aux++;
        });
      }
      const URL = this.baseURL + "/" + schema + "/" + storeName + formatParams;
      axios
        .get(URL, {
          withCredentials: true
        })
        .then(response => {
          if (response.data.body.length >= 1000) {
            let offset;
            if (params === undefined) {
              params = {};
              offset = 1000;
            } else {
              if (params["offset"] === undefined) {
                offset = 1000;
              } else {
                offset = params["offset"] + 1000;
              }
            }
            params["offset"] = offset;
            this.downloadData(schema, storeName, params);
          }
          this.addData(storeName, response.data.body)
            .then(data => {
              resolve(data);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch(function(error) {
          console.log(error);
          reject(error);
        });
    });
    return dataBody;
  }
  addData(store: string, data: any[]) {
    const dataBody = new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.dbVersion);
      request.onsuccess = () => {
        this.db = request.result;
        const transaction = this.db.transaction([store], "readwrite");
        const objectStore = transaction.objectStore(store);
        data.forEach(entry => {
          objectStore.add(entry);
        });
        transaction.oncomplete = () => {
          resolve(data);
        };
      };
    });
    return dataBody;
  }
  getData(store: string, key: any) {
    const dataBody = new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.dbVersion);
      request.onsuccess = () => {
        this.db = request.result;
        const data: any[] = [];
        const transaction = this.db.transaction([store], "readonly");
        const objectStore = transaction.objectStore(store);
        if (key === undefined) {
          const request = objectStore.openCursor();
          request.onsuccess = (e: any) => {
            const cursor = e.target.result;
            if (cursor) {
              data.push(cursor.value);
              cursor.continue();
            } else {
              resolve(data);
            }
          };
        } else {
          const request = objectStore.get(key);
          request.onsuccess = (e: any) => {
            resolve(request.result);
          };
        }
      };
    });
    return dataBody;
  }
  insertData(
    schema: string,
    store: string,
    data: any[],
    indexedDBStatus = "create"
  ) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.dbVersion);
      request.onsuccess = () => {
        this.db = request.result;
        const transaction = this.db.transaction([store], "readwrite");
        const objectStore = transaction.objectStore(store);
        data.forEach(entry => {
          entry._id = this.IDGenerator();
          entry.indexedDBStatus = indexedDBStatus;
          objectStore.add(entry);
        });
        transaction.oncomplete = () => {
          this.syncData(schema, store)
            .then(sync => {
              resolve(data);
            })
            .catch(error => {
              if (navigator.onLine) {
                data.forEach(deleteData => {
                  const request = indexedDB.open(this.dbName, this.dbVersion);
                  request.onsuccess = () => {
                    const transaction = this.db.transaction([store], "readwrite");
                    const objectStore = transaction.objectStore(store);
                    objectStore.delete(deleteData._id);
                    resolve(true);
                  };
                });
              }
              reject(error);
            });
        };
      };
    });
  }
  updateData(
    store: string,
    data: any[],
    sync = false,
    schema = "",
    indexedDBStatus = "update"
  ) {
    const dataBody = new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.dbVersion);
      request.onsuccess = () => {
        this.db = request.result;
        const transaction = this.db.transaction([store], "readwrite");
        const objectStore = transaction.objectStore(store);
        data.forEach((entry: any) => {
          if (sync) {
            entry.indexedDBStatus = indexedDBStatus;
          }
          objectStore.put(entry);
        });
        transaction.oncomplete = () => {
          if (sync) {
            this.syncData(schema, store)
              .then(() => {
                resolve(data);
              })
              .catch(error => {
                reject(error);
              });
          } else {
            resolve(data);
          }
        };
      };
    });
    return dataBody;
  }
  deleteData(schema: string, store: string, key: any) {
    return new Promise((resolve, reject) => {
      this.getData(store, key).then((data: any) => {
        this.updateData(store, [data], true, schema, "delete")
          .then(() => {
            const request = indexedDB.open(this.dbName, this.dbVersion);
            request.onsuccess = () => {
              const transaction = this.db.transaction([store], "readwrite");
              const objectStore = transaction.objectStore(store);
              objectStore.delete(data._id);
              resolve(true);
            };
          })
          .catch(error => {
            reject(error);
          });
      });
    });
  }
  syncData(schema: string, store: string) {
    const dataBody = new Promise((resolve, reject) => {
      const URL = this.baseURL + "/" + schema + "/" + store;
      let sendData;
      this.getData(store, undefined).then((data: any) => {
        data.forEach((object: any) => {
          if (object.indexedDBStatus === "create") {
            sendData = object;
            delete sendData.indexedDBStatus;
            sendData = [sendData];
            axios
              .post(URL, sendData, {
                withCredentials: true
              })
              .then(response => {
                this.updateData(store, response.data.body).then(() => {
                  resolve(true);
                });
              })
              .catch(error => {
                reject(error);
              });
          } else if (object.indexedDBStatus === "update") {
            sendData = object;
            delete sendData.indexedDBStatus;
            sendData = [sendData];
            axios
              .put(URL, sendData, {
                withCredentials: true
              })
              .then(response => {
                this.updateData(store, response.data.body).then(() => {
                  resolve(true);
                });
              })
              .catch(error => {
                reject(error);
              });
          } else if (object.indexedDBStatus === "delete") {
            axios
              .delete(URL + "/" + object._id, {
                withCredentials: true
              })
              .then(() => {
                resolve(true);
              })
              .catch(error => {
                reject(error);
              });
          }
        });
      });
    });
    return dataBody;
  }
  uploadFile(
    store: string,
    key: string,
    endPoint: string,
    form: any,
    callback: any
  ) {
    return new Promise((resolve, reject) => {
      const URL = this.baseURL + "/" + endPoint;
      const options = {
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          this.uploadLoaded = loaded;
          this.uploadTotal = total;
          this.uploadPercent = percent;
          callback(this.uploadLoaded, this.uploadTotal, this.uploadPercent);
        },
        withCredentials: true
      };
      axios.post(URL, form, options).then(res => {
        this.getData(store, key)
          .then((data: any) => {
            data = res.data.body[0];
            this.updateData(store, [data]).then(dataResponse => {
              resolve(dataResponse);
            });
          })
          .catch(error => {
            reject(error);
          });
      });
    });
  }
}

export default Storage;
