export default [
    {
      path: "/collection",
      name: "Collection",
      component: () => import("../views/OfficeSelector.vue")
    },
    {
      path: "/collection/:_id",
      name: "Collection Main",
      component: () => import("../views/Collection/Main.vue")
    },
    {
      path: "/collection/:_id/create",
      name: "Collection Create",
      component: () => import("../views/Collection/Create.vue")
    },
    {
      path: "/collection/:_id/edit/:object_id",
      name: "Collection Edit",
      component: () => import("../views/Collection/Edit.vue")
    },
    {
      path: "/collection/:_id/pay/:object_id",
      name: "Collection Pay",
      component: () => import("../views/Collection/Pay.vue")
    }
];