export default [
    {
      path: "/branch",
      name: "Branch",
      component: () => import("../views/OfficeSelector.vue")
    },
    {
      path: "/branch/:_id",
      name: "Branch Main",
      component: () => import("../views/Branch/Main.vue")
    },
    {
      path: "/branch/:_id/create",
      name: "Branch Create",
      component: () => import("../views/Branch/Create.vue")
    },
    {
      path: "/branch/:_id/edit/:object_id",
      name: "Branch Edit",
      component: () => import("../views/Branch/Edit.vue")
    }
  ];