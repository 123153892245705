export default [
    {
      path: "/office",
      name: "Office",
      component: () => import("../views/Office/Main.vue")
    },
    {
      path: "/office/create",
      name: "Office Create",
      component: () => import("../views/Office/Create.vue")
    },
    {
      path: "/office/edit/:object_id",
      name: "Office Edit",
      component: () => import("../views/Office/Edit.vue")
    }
  ];
