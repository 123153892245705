export default [
    {
      path: "/affiliate",
      name: "Affiliate",
      component: () => import("../views/OfficeSelector.vue")
    },
    {
      path: "/affiliate/:_id",
      name: "Affiliate Main",
      component: () => import("../views/Affiliate/Main.vue")
    },
    {
      path: "/affiliate/:_id/create",
      name: "Affiliate Create",
      component: () => import("../views/Affiliate/Create.vue")
    },
    {
      path: "/affiliate/:_id/edit/:object_id",
      name: "Affiliate Edit",
      component: () => import("../views/Affiliate/Edit.vue")
    },
    {
      path: "/affiliate/:object_id/files",
      name: "Affiliate Files",
      component: () => import("../views/Affiliate/Files.vue")
    },
    {
      path: "/affiliate/:object_id/files/upload",
      name: "Affiliate Upload File",
      component: () => import("../views/Affiliate/UploadFile.vue")
    }
];
