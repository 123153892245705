export default [
    {
      path: "/login",
      name: "Login",
      component: () => import("../views/Auth/Login.vue")
    },
    {
      path: "/emailConfirmation/:code",
      name: "EmailConfirmation",
      component: () => import("../views/Auth/EmailConfirmation.vue")
    }
  ];
