<template>
    <div>
        <v-menu
          bottom
          min-width="200px"
          rounded
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              x-large
              v-on="on"
            >
              <v-avatar
                v-if="avatarURL"
                size="32"
              >
                <img :src="avatarURL">
              </v-avatar>
              <v-avatar
              v-else
                color="brown"
                size="32"
              >
                <span class="white--text headline">{{ fullName.substring(0, 1).toUpperCase() }}</span>
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar
                  v-if="avatarURL"
                >
                  <img :src="avatarURL">
                </v-avatar>
                <v-avatar
                  v-else
                  color="brown"
                >
                  <span class="white--text headline">{{ fullName.substring(0, 1).toUpperCase() }}</span>
                </v-avatar>
                <h3 class="mt-2">{{ fullName }}</h3>
                <p class="caption mt-1">
                  {{ email }}
                </p>
                <v-divider class="my-2"></v-divider>
                <v-btn
                  depressed
                  rounded
                  text
                  @click="darkMode"
                  v-if="!$vuetify.theme.dark"
                >
                  Modo Oscuro
                </v-btn>
                <v-btn
                  depressed
                  rounded
                  text
                  @click="darkMode"
                  v-else
                >
                  Modo Claro
                </v-btn>
                <v-divider class="my-2"></v-divider>
                <v-btn
                  depressed
                  rounded
                  text
                >
                  Informar un Problema
                </v-btn>
                <v-divider class="my-2"></v-divider>
                <v-btn
                  depressed
                  rounded
                  text
                  to="/settings"
                >
                  Ajustes
                </v-btn>
                <v-divider class="my-2"></v-divider>
                <v-btn
                  depressed
                  rounded
                  text
                  @click="closeSession"
                >
                  Cerrar Sesión
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
    </div>
</template>

<script>
import Session from "@/modules/session";
import Swal from "sweetalert2";

  import Vue from 'vue'
    export default Vue.extend({
        name: "HeaderAvatar",
        data() {
            return {
              avatarURL: false
            }
        },
        computed: {
          fullName: () => {
              return localStorage.getItem('user.name');
          },
          email: () => {
              return localStorage.getItem('user.email');
          },
        },
  beforeCreate() {
    if (localStorage.getItem('darkTheme')) {
      this.$vuetify.theme.dark = (localStorage.getItem('darkTheme') == 'true');
    }
  },
  mounted() {
    if (localStorage.getItem("user.avatarURL") != "null") {
        this.avatarURL =
        localStorage.getItem("user.avatarURL") +
        "?seed=" +
        Math.floor(Math.random() * 10000000);
    }
  },
  methods: {
    darkMode() {
      const darkMode = !this.$vuetify.theme.dark
      this.$vuetify.theme.dark = darkMode;
      localStorage.setItem('darkTheme', darkMode.toString());
    },
    closeSession() {
      Swal.fire({
        title: "¿Desea cerra la sesión?",
        showCancelButton: true,
        confirmButtonText: `Sí`,
        cancelButtonText: "No"
      }).then(result => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: "warning",
            title: "Cerrando sesión",
            text: "Estamos haciendo unas comprobaciones...",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            stopKeydownPropagation: true,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              setInterval(() => {
                const content = Swal.getContent();
                if (content) {
                  const b = content.querySelector("b");
                  if (b) {
                    b.textContent = Swal.getTimerLeft();
                  }
                }
              }, 100);
            }
          });
          const session = new Session();
          session.sigOut();
        }
      });
    },
  },
    })
</script>