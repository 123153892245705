









import Advertising from '@/components/Home/Advertising.vue'
import PriceTable from '@/components/Home/PriceTable.vue'
export default {
    name: 'DashboardHome',
    components: {
        Advertising,
        PriceTable
    },
}
