<template>
    <div>
        <v-carousel
    cycle
    height="250"
    hide-delimiter-background
    show-arrows-on-hover
  >
    <v-carousel-item
        v-for="(slide, i) in slides"
        :key="i"
        >
        <v-sheet
            :color="colors[i]"
            height="100%"
        >
            <v-row
            class="fill-height"
            align="center"
            justify="center"
            >
            <div class="display-3">
                {{ slide }}
            </div>
            </v-row>
        </v-sheet>
        </v-carousel-item>
    </v-carousel>
    </div>
</template>

<script>
export default {
    name: 'HomeAdvertising',
    data () {
      return {
        colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
        ],
        slides: [
          'Publicidad 1',
          'Publicidad 2',
          'Publicidad 3',
          'Publicidad 4',
          'Publicidad 5',
        ],
      }
    },
}
</script>